
import Link from "next/link";
import React from "react";


const HomeFooter = () => (
  <div className="container-fluid">
    <div className="container footer line-container">
        <div className="navbar-nav line6 line-footer">
          <ul>
              <li className="nav-item"><Link href="/ayuda/aviso_legal">AVISO LEGAL</Link></li>
              <li className="nav-item "><Link href="/ayuda/politica_privacidad">POLÍTICA DE PRIVACIDAD</Link></li>
              <li className="nav-item"><Link href="/ayuda/politica_cookies">POLÍTICA DE COOKIES</Link></li>
              <li className="nav-item"><Link href="/ayuda/condiciones_contratacion">CONDICIONES DE CONTRATACIÓN</Link></li>
              <li className="nav-item"><a rel="noreferrer"  target="_blank" href="https://content.eccediciones.com/documentos/desentimiento.pdf" alt="">CONTRATO DE DESENTIMIENTO</a></li>
              <li className="nav-item"><a rel="noreferrer"  target="_blank" href="https://compliance.legalsending.com/canal/?C=48602549019023114" alt="">CANAL ÉTICO</a></li>
          </ul>
          <p>Copyright © 2022 El Catálogo del Cómic S.L. Todos los derechos reservados. </p>
        </div>
    </div>
  </div>

);

export default React.memo(HomeFooter);
